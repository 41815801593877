import React from "react";
import "./title.scss";

const classNameConstructor = (
	themTitle: "white" | "black" | undefined,
	classTitle: string | undefined
) => {
	let returnClass: string = "title";

	if (themTitle === undefined) {
		returnClass += " black";
	} else {
		returnClass += " " + themTitle;
	}

	if (classTitle !== undefined) {
		returnClass += " " + classTitle;
	}

	return returnClass;
};

interface iTitle {
	type: "h1" | "h2" | "h3";
	theme?: "white" | "black";
	className?: string;
	children?: React.ReactNode;
}

const Title = ({ children, type, theme, className }: iTitle) => {
	switch (type) {
		case "h1":
			return (
				<h1 className={"h1 " + classNameConstructor(theme, className)}>
					{children}
				</h1>
			);
		case "h2":
			return (
				<h2 className={"h2 " + classNameConstructor(theme, className)}>
					{children}
				</h2>
			);
		default:
		case "h3":
			return (
				<h3 className={"h3 " + classNameConstructor(theme, className)}>
					{children}
				</h3>
			);
	}
};

export default Title;
