import React from "react";
import "./text.scss";

interface iText {
	className?: string;
	children?: React.ReactNode;
	theme?: "black" | "white";
}

const Text = ({ children, className, theme }: iText) => {
	return (
		<p
			className={
				"text" +
				(className === undefined ? "" : " " + className) +
				(theme === undefined ? "" : " " + theme)
			}
		>
			{children}
		</p>
	);
};

export default Text;
