import React, { useEffect } from "react";

type OutsideClickHandler = (event: MouseEvent) => void;

function useOutsideClick<T extends HTMLElement = HTMLElement>(
	ref: React.RefObject<T>,
	handler: OutsideClickHandler
) {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const el = ref?.current;

			// Не делаем ничего, если кликнули на элемент или его потомка
			if (!el || el.contains(event.target as Node)) {
				return;
			}

			handler(event);
		};

		// Добавляем слушатель событий при монтировании компонента
		document.addEventListener("mousedown", handleClickOutside);

		// Удаляем слушатель событий при размонтировании компонента
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, handler]);
}

export default useOutsideClick;
