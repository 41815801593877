import React from "react";
import "./catalog.scss";
import BuyItems from "./images";
import { Article, Text, Title } from "components";
import { isMobile } from "react-device-detect";

const Catalog = () => {
	return (
		<main className={"catalog"}>
			<Title type={"h2"} className={"page-name"} theme={"white"}>
				Каталог изделий
			</Title>
			<section className={"articles"}>
				<Title type={"h2"}>Каталог наших изделий</Title>
				<div className={"items"}>
					{Object.keys(BuyItems).map((key) => (
						<Article
							key={key}
							src={BuyItems[key].src}
							name={BuyItems[key].name}
						>
							{BuyItems[key].content.map((item, index) => (
								<li key={index}>{item}</li>
							))}
						</Article>
					))}
				</div>
			</section>
			<section className={"goals"}>
				<Title type={"h3"}>
					ЭБН предназначен для использования на следующих авиационных
					системах регистрации данных:
				</Title>
				<Text>
					МСРП-256, МСРП-12-96, МСРП-64, БУР-1, БУР-3, БУР-4, БУР-92А,
					ТЕСТЕР-У3, ТЕСТЕР- М, П503, П507, МС-61, МАРС-БМ,
					устанавливаются на ВС: Антонов: Ан-12, Ан-22, Ан-24, Ан-26,
					Ан-32, Ан-38, Ан-72, Ан- 74, Ан-124, Ан-225, Ан-140; Ильюшин
					Ил-18, Ил-62(М), Ил-86, Ил-76; Туполев: Ту-134, Ту-154 (Б2,
					М), Яковлев: Як-40, Як-42; Миль: Ми-8, Ми-17, Ми-171, Ми-35;
					Камов: Ка-26, Ка-32 и другие.
				</Text>
			</section>
			{isMobile ? (
				<Text className={"tip"}>
					<i>
						*Отправляя заявку, вы соглашаетесь на обработку
						персональных данных
					</i>
				</Text>
			) : null}
		</main>
	);
};

export default Catalog;
