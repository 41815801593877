import React from "react";
import "./about.scss";
import { NavButton, Review, Text, Title } from "components";

import { AboutPic } from "./images";
import { PATHS } from "router/paths";

const About = () => {
	return (
		<main className={"about"}>
			<Title type={"h2"} theme={"white"} className={"page-name"}>
				О компании
			</Title>
			<section className={"description"}>
				<img className={"image"} src={AboutPic} alt={"Земля"} />
				<Text>
					«ТМПД» - разработчик и производитель регистраторов
					параметрических данных и сопутствующего оборудования для
					считывания накопленных данных, а также программного
					обеспечения для декодирования, расшифровки и обработки
					полётных данных. Обработка и анализ полетной информации
					(ОАПИ) способствует своевременному реагированию на
					возникающие отклонения от норм эксплуатации воздушного
					судна. Наша продукция используется в гражданской авиации,
					авиации общего назначения, грузовых самолетах, самолетах и
					вертолетах специального назначения и беспилотных авиационных
					системах.
				</Text>
			</section>
			<section className={"advantages"}>
				<Title type={"h3"}>Наши преимущества</Title>
				<ul className={"list"}>
					<li>
						<Text>
							Производим разработку и изготовление электронных
							накопителей и регистраторов любой сложности в любой
							сфере информационной технологии.
						</Text>
					</li>
					<li>
						<Text>
							Разработка программного продукта по Техническому
							Заданию заказчика любой направленности, связанной с
							фиксацией, накоплением, анализом, управлением,
							ведением массивов данных.
						</Text>
					</li>
					<li>
						<Text>
							Визуализация любых объёмов данных как в статическом
							варианте, так и в серверном потоке.
						</Text>
					</li>
					<li>
						<Text>
							Опыт оцифровки и передачи данных через спутниковые
							системы.
						</Text>
					</li>
					<li>
						<Text>
							Колоссальный опыт кодирования, декодирования,
							компрессии, декомпрессии и расшифровки цифровых
							массивов.
						</Text>
					</li>
					<li>
						<Text>
							Внедрение интерактивных систем управления
							производственными процессами любой сложности и
							направленности. Работа с программируемыми
							промышленными контроллерами.
						</Text>
					</li>
				</ul>
			</section>
			<section className={"experience"}>
				<Title type={"h3"}>Наш опыт и услуги</Title>
				<ul className={"list"}>
					<li>
						<Text>
							Проводим расшифровку, обработку и экспресс-анализ
							полётных данных.
						</Text>
					</li>
					<li>
						<Text>
							Готовим отчет о рабочем состоянии твердотельных
							накопителей параметрической информации согласно
							требованиям ИКАО ПРИЛОЖЕНИЕ 6 Дополнение Д.
						</Text>
					</li>
					<li>
						<Text>
							Проводим обучение персонала авиакомпаний по
							специализации в области обработки и анализа полётной
							информации, с выдачей соответствующих сертификатов.
							Возможно проведение обучения на территории
							заказчика.
						</Text>
					</li>
					<li>
						<Text>
							Имеем опыт подготовки и организации учебного
							процесса в учебном заведении (МГТУ ГА). Компьютерный
							класс с рабочими местами и серверным оснащением для
							подготовки персонала.
						</Text>
					</li>
					<li>
						<Text>
							Своевременные консультации и помощь нашим партнерам
							как в гарантийный срок, так и по его истечении.
						</Text>
					</li>
					<li>
						<Text>
							Многолетние партнёрские отношения с
							Межгосударственным авиационным комитетом (МАК) в
							области расшифровки и анализа полётных данных.
						</Text>
					</li>
				</ul>
			</section>
			<section className={"numbers"}>
				<Title type={"h3"}>Компания в цифрах</Title>
				<ul className={"list"}>
					<li>
						<Text>
							«ТМПД» функционирует на рынке СНГ и дальнего
							зарубежья <strong>более 30 лет.</strong>
						</Text>
					</li>
					<li>
						<Text>
							Партнёрами и клиентами предприятия являются{" "}
							<strong>около 200 компаний</strong> и{" "}
							<strong>14 производителей</strong> более чем в{" "}
							<strong>50 странах.</strong>
						</Text>
					</li>
					<li>
						<Text>
							Опыт в обработке полётной информации для{" "}
							<strong>более 300 типов</strong> воздушных судов.
						</Text>
					</li>
					<li>
						<Text>
							Опыт работы в области обработки и анализа полетной
							информации с <strong>более 60 типов</strong>{" "}
							бортовых регистраторов.
						</Text>
					</li>
				</ul>
			</section>
			<section className={"reviews"}>
				<Title type={"h2"}>Отзывы наших клиентов</Title>
				<Review name={"«РОССИЯ»"} type={"Авиакомпания"}>
					Позвольте поблагодарить Вас за создание «Специального
					программного обеспечения «LUCH» алгоритмической обработки и
					анализа информации, зарегистрированной на самолётах типа
					Ан-148-100 бортовыми средствами объективного контроля БСТО»
					по техническому заданию и под требования ОАО «Авиакомпания
					«Россия»». Специалисты ОАО «Авиакомпания «Россия»» с 2013
					года успешно используют данную программу. При этом в нашей
					авиакомпании упростился порядок поиска отказов и
					неисправностей систем штурвального управления,
					автоматического управления, расширились возможности
					мониторинга параметров работы двигателей. Кроме того,
					указанное программное обеспечение позволяет производить
					полноценный контроль вибрационного состояния двигателей
					Д-436-148 и позволило уточнить методику проверки
					летно-технических характеристик самолетов в отношении
					расхода топлива.
				</Review>
				<Review name={"«Utair»"} type={"Авиакомпания"}>
					От имени авиакомпании «UTair» я хотел бы поблагодарить вас
					за успешное и взаимовыгодное сотрудничество, касаемое
					устройств и программного обеспечения для накопления, сбора,
					обработки и анализа полётной информации. Наша компания
					использует вышеперечисленные продукты с 2018 года, и мы
					очень ценим простоту и удобство использования. Оперативные и
					статистические результаты существенно способствуют
					своевременному устранению и предотвращению отказов систем
					самолетов.
				</Review>
				<Review name={"ФГУП СибНИА"} type={"им. С. А. Чаплыгина"}>
					От имени ФГУП «СибНИА им. С.А. Чаплыгина» позвольте
					поблагодарить вас за создание «Специального программного
					обеспечения «LUCH»», предназначеного для алгоритмической
					обработки и анализа информации, зарегистрированной бортовыми
					средствами записи параметров на самолётах типа ЯК-40.
					Специалисты нашей компании с 2013 года успешно используют
					данное СПО, оценили по достоинству простоту и удобство
					обработки полетной информации. СПО «LUCH» позволяет
					оперативно производить полноценный контроль состояния
					систем, агрегатов и летно-технических характеристик
					самолетов. Полученные результаты способствуют своевременному
					устранению и предотвращению отказов в работе систем.
				</Review>
			</section>

			<section className={"buy-list"}>
				<div className={"name"}>
					<Title type={"h3"}>
						Смотрите полный перечень изделий в каталоге
					</Title>
					<Text>
						Эксплуатационные бортовые накопители нового поколения, а
						также нестандартные устройства в нашем каталоге
					</Text>
				</div>
				<NavButton
					href={PATHS.CATALOG}
					type={"button"}
					theme={"btn-green"}
				>
					Смотреть каталог
				</NavButton>
			</section>
		</main>
	);
};

export default About;
