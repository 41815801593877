import React from "react";
import "./article.scss";
import { Text } from "components";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import { PATHS } from "router/paths";

//<a href = "mailto: abc@example.com">Send Email</a>

interface iArticle {
	src: string;
	name: string;
	addition?: string;
	children?: React.ReactNode;
}

const Article = ({ src, name, addition, children }: iArticle) => {
	const createHref = (itemName: string) => {
		name = encodeURIComponent(name);
		return `mailto:mail@tmpd.tech?subject=%D0%97%D0%B0%D1%8F%D0%B2%D0%BA%D0%B0%20%D0%BD%D0%B0%20%22${itemName}%22&body=%D0%97%D0%B0%D1%8F%D0%B2%D0%BA%D0%B0%20%D0%BD%D0%B0%20%22${itemName}%22.%20%D0%92%D0%BE%D0%B7%D0%BC%D0%BE%D0%B6%D0%BD%D0%BE%20%D0%BB%D0%B8%20%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%B9%20%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8%20%D0%BE%20%D0%B4%D0%B0%D0%BD%D0%BD%D0%BE%D0%B9%20%D0%BF%D0%BE%D0%B7%D0%B8%D1%86%D0%B8%D0%B8%3F`;
	};

	return (
		<div className={"article"}>
			<img className={"image"} src={src} alt={name} />
			<p className={"name"}>{name}</p>
			<Text>
				<ul>{children}</ul>
			</Text>
			{addition === undefined ? null : <Text>({addition})</Text>}
			{isMobile ? (
				<a className={"button-send"} href={createHref(name)}>
					Отправить заявку*
				</a>
			) : (
				<NavLink className={"button-send"} to={PATHS.CONTACT}>
					Отправить заявку
				</NavLink>
			)}
		</div>
	);
};

export default Article;
