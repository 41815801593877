import EBN12 from "./EBN-12.webp";
import EBN12_24 from "./EBN-12_24.webp";
import EBN64 from "./EBN-64.webp";
import EBN_BYR3 from "./EBN-BYR3.webp";
import EBN_BYR1 from "./EBN-BYR1.webp";
import EBN_BYR4 from "./EBN-BYR4.webp";
import EBN_MC from "./EBN-MC.webp";
import EBN_Tester from "./EBN-Tester.webp";
import EBN_MARS from "./PBN-MARS.webp";
import EBN_OPAL from "./EBN-OPAL.webp";
import BS_12 from "./BS-12.webp";
import EBN_MODULES from "./EBN-MODULES.webp";

interface Card {
	src: string;
	name: string;
	content: string[];
}

const BuyItems: { [key: string]: Card } = {
	EBN12: {
		src: EBN12,
		name: "ЭБН-12",
		content: [
			"Данные - параметрические",
			"Система регистрации - МСРП-12-96 (на 12 разовых команд)",
			"ВС - Ан-8/10/12, Ан-24/26/30/38, Бе-12, Ка-27/29, Ил-18/20/22, Ту-134, Як-40",
			"Емкость записи - не менее 260 часов",
		],
	},
	EBN12_24: {
		src: EBN12_24,
		name: "ЭБН-12/24",
		content: [
			"Данные - параметрические",
			"Система регистрации - МСРП-12-96 (на 24 разовые команды)",
			"ВС - Ан-8/10/12, Ан-24/26/30/38, Бе-12, Ка-27/29, Ил-18/20/22, Ту-134, Як-40",
			"Емкость записи - не менее 260 часов",
		],
	},
	EBN64: {
		src: EBN64,
		name: "ЭБН-64 (256)",
		content: [
			"Данные - параметрические",
			"Система регистрации - МСРП-64, МСРП-256",
			"ВС - Ил-62, Ил-76, Ту-134, Ту-154, Як-42",
			"Емкость записи - не менее 260 часов",
		],
	},
	EBN_BYR3: {
		src: EBN_BYR3,
		name: "ЭБН-БУР3",
		content: [
			"Данные - параметрические",
			"Система регистрации - БУР3",
			"ВС - Ан-28, Ан-74, Ми-8",
			"Емкость записи - не менее 260 часов",
		],
	},
	EBN_BYR1: {
		src: EBN_BYR1,
		name: "ЭБН-БУР1",
		content: [
			"Данные - параметрические",
			"Система регистрации - БУР1",
			"ВС - Ан-28, Ан-74, Ми-8",
			"Емкость записи - не менее 260 часов",
		],
	},
	EBN_BYR4: {
		src: EBN_BYR4,
		name: "ЭБН-БУР4",
		content: [
			"Данные - параметрические",
			"Система регистрации - БУР-4",
			"ВС - Ан-3Т, Ан-72, Ан-124",
			"Емкость записи - не менее 260 часов",
		],
	},
	EBN_MC: {
		src: EBN_MC,
		name: "ЭБН-МС",
		content: [
			"Данные - звуковые",
			"Система регистрации - МС-61 (П-503/507)",
			"ВС - Ан-8/10/12, Ан-24/26/30/38, Бе-12, Ка-27/29, Ил-18/20/22, Ту-134, Як-40",
			"Емкость записи - не менее 30 часов",
		],
	},
	EBN_Tester: {
		src: EBN_Tester,
		name: "ЭБН-ТЕСТЕР",
		content: [
			"Данные - параметрические",
			"Система регистрации - Тестер-У3 сер.2,3",
			"ВС - Ан-32, Ан-72, Ан-124",
			"Емкость записи - не менее 260 часов",
		],
	},
	EBN_MARS: {
		src: EBN_MARS,
		name: "ЭБН-МАРС",
		content: [
			"Данные - звуковые",
			"Система регистрации - МАРС-БМ",
			"ВС - Ту-134, Ту-154, Ил-76, Як-42",
			"Емкость записи - не менее 30 часов",
		],
	},
	EBN_OPAL: {
		src: EBN_OPAL,
		name: "ЭБН-ОПАЛ",
		content: [
			"Данные - звуковые",
			"Система регистрации - ОПАЛ",
			"ВС - Ан-72, Ан-74",
			"Емкость записи - не менее 30 часов",
		],
	},
	EBN_REWRITE: {
		src: BS_12,
		name: "Устройство перезаписи (Блок считывания) БС-12",
		content: [
			"Устройство перезаписи информации с ЭБН. Исполнение как с переключателем режимов, так и без него.",
		],
	},
	EBN_MODULES: {
		src: EBN_MODULES,
		name: "Съёмные блоки памяти ЭБН",
		content: [],
	},
};

export default BuyItems;
