import React from "react";
import { Title, Text } from "components";
import "./fact.scss";

interface iFact {
	src: string;
	alt: string;
	title: string;
	children?: React.ReactNode;
}

const Fact = ({ src, alt, title, children }: iFact) => {
	return (
		<div className={"fact"}>
			<img className={"image"} src={src} alt={alt} />
			<Title type={"h3"}>{title}</Title>
			<Text className={"text-center"}>{children}</Text>
		</div>
	);
};

export default Fact;
