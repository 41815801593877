import React from "react";
import { NavLink } from "react-router-dom";
import "./navButton.scss";

interface ButtonProps {
	href: string;
	type: "text" | "button";
	theme?: "btn-gray" | "btn-green";
	fullWidth?: boolean;
	className?: string;
	children?: React.ReactNode;
}

const NavButton = ({
	href,
	type,
	fullWidth,
	theme,
	className,
	children,
}: ButtonProps) => {
	const ButtonCheck = (
		linkType: string,
		buttonThem: "btn-gray" | "btn-green" | undefined
	): string => {
		if (linkType === "button") {
			if (buttonThem === undefined) {
				return "btn-gray";
			}
			return buttonThem;
		}
		return "";
	};

	return (
		<NavLink
			className={
				"nav-button" +
				`${fullWidth ? "full-width" : ""} ` +
				`${type} ` +
				`${className === undefined ? "" : className} ` +
				`${ButtonCheck(type, theme)}`
			}
			to={href}
		>
			{children}
		</NavLink>
	);
};

export default NavButton;
