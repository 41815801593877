import React from "react";
import "./contact.scss";
import { Text, Title } from "components";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Telegram, WhatsApp } from "pages/Contact/images";

const Contact = () => {
	const notify = (message: string) =>
		toast(message, {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: false,
			progress: undefined,
			theme: "light",
		});

	return (
		<main className={"contacts"}>
			<Title type={"h2"} className={"page-name"} theme={"white"}>
				Контакты
			</Title>
			<section className={"info"}>
				<Title type={"h2"}>Контактная информация</Title>
				<Text className={"annotation"}>
					<i>(Нажмите чтобы скопировать)</i>
				</Text>
				<div className={"block"}>
					<div className={"card"}>
						<Title type={"h3"}>Электронная почта</Title>
						<p
							className={"copy"}
							onClick={() => {
								(() =>
									navigator.clipboard.writeText(
										"mail@tmpd.tech"
									))();
								notify("Вы скопировали почту");
							}}
						>
							mail@tmpd.tech
						</p>
					</div>
					<div className={"card"}>
						<Title type={"h3"}>Телефон</Title>
						<p
							className={"copy"}
							onClick={() => {
								(() =>
									navigator.clipboard.writeText(
										"+7 912 895-11-77"
									))();
								notify("Вы скопировали номер");
							}}
						>
							+7 912 895-11-77
							<img className={"messenger"} src={Telegram} />
							<img className={"messenger"} src={WhatsApp} />
						</p>
					</div>
					<div className={"card"}>
						<Title type={"h3"}>Адрес</Title>
						<p
							className={"copy"}
							onClick={() => {
								(() =>
									navigator.clipboard.writeText(
										"г. Челябинск, ул. Цвиллинга, 57"
									))();
								notify("Вы скопировали адрес");
							}}
						>
							г. Челябинск, ул. Цвиллинга, 57
						</p>
					</div>
				</div>
			</section>
			<ToastContainer
				position='top-center'
				autoClose={5000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable={false}
				pauseOnHover={false}
				theme='light'
			/>
		</main>
	);
};

export default Contact;
