import React from "react";
import "./review.scss";
import QuoteImg from "./images/quote.svg";

interface iReview {
	name: string;
	type: string;
	children?: React.ReactNode;
}

const Review = ({ name, type, children }: iReview) => {
	return (
		<div className={"review"}>
			<img className={"image"} src={QuoteImg} alt={"Кавычка"} />
			<p className={"base-text"}>{children}</p>
			<div className={"title"}>
				<p className={"name"}>{name}</p>
				<p className={"type"}>{type}</p>
			</div>
		</div>
	);
};

export default Review;
