import React from "react";
import "./footer.scss";

const Footer = () => {
	return (
		<footer className='footer'>
			&copy; 2023 Технологии мониторинга полётных данных «ТМПД».
		</footer>
	);
};

export default Footer;
