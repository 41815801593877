import React from "react";
import { Navigate, Route, Routes, BrowserRouter } from "react-router-dom";
import { About, Catalog, Home, Luch, NotFound, Contact } from "pages";
import { PATHS } from "./paths";
import { Footer, Header } from "components";

const MyRoutes = () => {
	return (
		<BrowserRouter>
			<Header />
			<Routes>
				<Route path={PATHS.HOME} element={<Home />} />
				<Route path={PATHS.ABOUT} element={<About />} />
				<Route path={PATHS.LUCH} element={<Luch />} />
				<Route path={PATHS.CATALOG} element={<Catalog />} />
				<Route path={PATHS.CONTACT} element={<Contact />} />

				{/*Error*/}
				<Route path={PATHS.NOTFOUND} element={<NotFound />} />
				<Route path='*' element={<Navigate to={PATHS.NOTFOUND} />} />
			</Routes>
			<Footer />
		</BrowserRouter>
	);
};

export default MyRoutes;
