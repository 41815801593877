import Utair from "./utair.webp";
import Polar from "./polar.webp";
import MAK from "./mak.webp";
import Russia from "./russia.svg";
import HabAvia from "./habavia.webp";
import Angara from "./angara.webp";
import SUBNIA from "./subnia.svg";
import KrasAvia from "./krasavia.svg";
import TourHan from "./tourhan.webp";
import AirBridge from "./airbridge.webp";
import Aviacon from "./aviacon.webp";
import Snab from "./snab.webp";
import Tylpar from "./tylpar.webp";
import Volga from "./volga.webp";
import Iraero from "./iraero.svg";

interface Image {
	src: string;
	alt: string;
}

const Logos: { [key: string]: Image } = {
	Utair: {
		src: Utair,
		alt: "Авиакомпания «Utair»",
	},
	MAK: {
		src: MAK,
		alt: "Межгосударственный авиационный комитет",
	},
	Russia: {
		src: Russia,
		alt: "Авиакомпания «Россия»",
	},
	HabAvia: {
		src: HabAvia,
		alt: "Авиакомпания «ХабАвиа»",
	},
	Angara: {
		src: Angara,
		alt: "Авиакомпания «Ангара»",
	},
	Polar: {
		src: Polar,
		alt: "Авиакомпания «Полярные авиалинии»",
	},
	SUBNIA: {
		src: SUBNIA,
		alt: "Сибирский научно-исследовательский институт авиации им. С. А. Чаплыгина",
	},
	KrasAvia: {
		src: KrasAvia,
		alt: "Авиакомпания «КрасАвиа»",
	},
	TourHan: {
		src: TourHan,
		alt: "Авиакомпания «Турхан авиа»",
	},
	AirBridge: {
		src: AirBridge,
		alt: "Авиакомпания «AirBridgeCargo»",
	},
	Aviacon: {
		src: Aviacon,
		alt: "Авиакомпания «Aviacon»",
	},
	Snab: {
		src: Snab,
		alt: "Авиакомпания «Авиатехснаб»",
	},
	Tylpar: {
		src: Tylpar,
		alt: "«Тулпар Техник»",
	},
	Volga: {
		src: Volga,
		alt: "Авиакомпания «Волга-Днепр»",
	},
	Iraero: {
		src: Iraero,
		alt: "Авиакомпания «Iraero»",
	},
};

export default Logos;
