import React from "react";
import MyRoutes from "router/routers";

import "style/null.scss";
import "style/app.scss";

function App() {
	return (
		<div className='app' id='app'>
			<MyRoutes />
		</div>
	);
}

export default App;
